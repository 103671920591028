<template>
  <div class="noise-layer"></div>
  <div class="holder-p5" ref="canvas"></div>
</template>

<style>
.noise-layer {
  background-image: url("@/assets/doia-noise-160.jpg");
  width: 100vw;
  height: 100vh;
  background-blend-mode: overlay;
  opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
}
</style>

<script>
import p5 from "p5";

export default {
  name: "P5Component",
  data() {
    return {
      p5Instance: null,
      parts: [],
      mouseParts: [],
    };
  },
  methods: {
    sketch(p) {
      let self = this;
      let initialParts = 9;
      let initialMouseParts = 5;
      let mousePart;

      p.setup = function () {
        p.createCanvas(p.windowWidth, p.windowHeight);
        p.background(255);
        p.frameRate(24);
        for (let i = 0; i < initialParts; i++) {
          let newPart = new self.Particle(p);
          self.parts.push(newPart);
        }

        for (let i = 0; i < initialMouseParts; i++) {
          let newPart = new self.Particle(p);
          self.mouseParts.push(newPart);
        }
        mousePart = new self.Particle(p, p.mouseX, p.mouseY);
      };

      p.draw = function () {
        p.background(255, 255, 255, 10);
        for (let part of self.parts) {
          part.update(p);
          part.display(p);
        }

        for (let part of self.mouseParts) {
          part.update(p);
          part.displayNew(p);
        }

        mousePart.updateMouse(p);
        mousePart.displayMouse(p);
      };

      p.mousePressed = function () {
        p.background(255, 255, 255, 255);
        self.parts = [];
        self.mouseParts = [];

        for (let i = 0; i < initialParts; i++) {
          let newPart = new self.Particle(p);
          self.parts.push(newPart);
        }

        for (let i = 0; i < initialMouseParts; i++) {
          let newPart = new self.Particle(p);
          self.mouseParts.push(newPart);
        }
        mousePart = new self.Particle(p, p.mouseX, p.mouseY);

        let partNew = new self.Particle(p, p.mouseX, p.mouseY);
        self.mouseParts.push(partNew);
      };

      p.windowResized = function () {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
        p.background(244, 244, 244);
      };
    },

    Particle: class {
      constructor(p, x = null, y = null) {
        this.p = p;
        this.xoff = this.p.random(150, 200);
        this.yoff = this.p.random(150, 200);
        this.initialX =
          x !== null ? x : p.map(p.noise(this.xoff), 0, 1, 0, p.width);
        this.initialY =
          y !== null ? y : p.map(p.noise(this.yoff), 0, 1, 0, p.height);
        this.x = this.initialX;
        this.y = this.initialY;
        this.size = p.random(6, 12);
        this.alpha = p.random(10, 50);
        this.col = p.random(255);
        this.shouldAnimate = false;
        this.xSpeed = 0.0095;
        this.ySpeed = 0.0095;
      }

      update(p) {
        this.xoff += this.xSpeed;
        this.yoff += this.ySpeed;

        this.x = p.map(p.noise(this.xoff), 0, 1, 0, p.width);
        this.y = p.map(p.noise(this.yoff), 0, 1, 0, p.height);

        this.col = p.map(p.noise(this.xoff), 0, 1, 100, 255);
        this.alpha = p.map(p.noise(this.xoff), 0, 1, 0, 64);
        this.size = p.map(p.noise(this.xoff), 0, 1, 1, 128);
      }

      updateMouse(p) {
        this.xoff += this.xSpeed;
        this.yoff += this.ySpeed;

        this.x = p.mouseX;
        this.y = p.mouseY;

        this.col = p.map(p.noise(this.xoff), 0, 1, 100, 255);
        this.alpha = p.map(p.noise(this.xoff), 0, 1, 0, 200);
        this.size = p.map(p.noise(this.xoff), 0, 1, 24, 72);
      }

      displayMouse(p) {
        p.fill(
          p.random(100, 220),
          this.col,
          p.random(69, 200),
          this.alpha * 0.2
        );
        p.ellipse(this.x, this.y, this.size, this.size);
      }

      display(p) {
        p.stroke(p.random(100, 220), this.col, p.random(69, 200), this.alpha);
        p.fill(
          p.random(100, 220),
          this.col,
          p.random(69, 200),
          this.alpha * 0.8
        );
        p.noStroke();
        p.ellipse(this.x, this.y, this.size, this.size);
      }

      displayNew(p) {
        this.xoff += this.xSpeed;
        this.yoff += this.ySpeed;

        p.fill(
          p.random(255, 255),
          this.col,
          p.random(69, 200),
          this.alpha * 0.8
        );
        p.ellipse(
          this.initialX + this.x * 0.5,
          this.initialY + this.y * 0.5,
          this.size,
          this.size
        );
      }
    },

    createCanvas() {
      this.p5Instance = new p5(this.sketch, this.$refs.canvas);
    },

    unmountCanvas() {
      this.p5Instance.remove();
    },
  },
  mounted() {
    this.createCanvas();
  },
  beforeUnmount() {
    this.unmonuntCanvas();
  },
};
</script>

import { createApp } from 'vue';
import App from './App.vue';

const app = createApp(App);

app.mount('#app');

// Hide the loader once Vue is ready.
const loader = document.getElementById('loader');
if (loader) loader.style.display = 'none';

<template>
  <div class="app">
    <div class="scroll-indicator" @click="goToStep2">
      <!-- <div class="scroll-indicator"> -->
      <svg
        ref="scrollIndicator"
        width="100"
        height="100"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 407.437 407.437"
      >
        <polygon
          points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815"
          fill="black"
        />
      </svg>
    </div>

    <div class="hero">
      <div v-if="showCookieBar" class="cookie-bar">
        Usamos cookies para mejorar la experiencia de este sitio. Al continuar
        tu navegación en este sitio apruebas el uso de cookies.
        <button @click="acceptCookies">Accept</button>
        <button @click="showCookieBar = false">Deny</button>
      </div>

      <div class="centered-svg">
        <img
          id="main-logo"
          ref="svgContainer"
          src="@/assets/doia-logo_02-negro.svg"
          @click="goToStep1"
        />
      </div>
    </div>

    <div class="hero">
      <div class="row">
        <h2 id="tagline" ref="tagline">
          Probamos, reimaginamos <br />y documentamos<br />
          flujos de trabajo creativo.
        </h2>
      </div>
    </div>

    <div class="bottom-row">
      <!-- Social Icons -->
      <div class="social-icons-container">
        <div class="social-icons" ref="socialIcons">
          <div class="button">
            <a
              class="social-icon-link"
              href="https://www.instagram.com/doia.studio/"
              target="_blank"
              ><AkInstagramFill class="icon"
            /></a>
          </div>
          <div class="button">
            <a
              class="social-icon-link"
              href="https://github.com/doia-studio"
              target="_blank"
              ><AkGithubFill class="icon"
            /></a>
          </div>
          <!-- <div class="button">
            <AkYoutubeFill class="icon" />
          </div> -->
          <div class="button">
            <a
              class="social-icon-link"
              href="https://observablehq.com/@doia-studio"
              target="_blank"
              ><SiObservable class="icon"
            /></a>
          </div>
          <!-- <div class="button">
            <AkTwitchFill class="icon" />
          </div> -->
        </div>
      </div>
      <div class="col">
        <p class="bottom-text" ref="bottomText">
          <span class="subtitle">Acercamos el potencial, 
          <br/>  
          el entendimiento y las herramientas para aprender a trabajar
          con datos, IA y herramientas colaborativas.</span>
          <br/>
          Exploramos y compartimos <br/> rutas deseables para integrar uso.
        </p>
      </div>
    </div>
    <div class="scroller">
      <div class="step" data-step="1"></div>
      <div class="step" data-step="2"></div>
    </div>
  </div>

  <div id="hero-back">
    <component :is="randomHeroComponent"></component>
  </div>
</template>

<script setup>
import { AkInstagramFill } from "@kalimahapps/vue-icons";
import { AkGithubFill } from "@kalimahapps/vue-icons";
// import { AkYoutubeFill } from "@kalimahapps/vue-icons";
import { SiObservable } from "@kalimahapps/vue-icons";
// import { AkTwitchFill } from "@kalimahapps/vue-icons";
</script>

<script>
import scrollama from "scrollama";
import TweenLite from "gsap";
import Hero00 from "./components/hero/Hero00.vue";
import Hero01 from "./components/hero/Hero01.vue";

export default {
  name: "App",
  components: {
    Hero00,
    Hero01,
  },
  computed: {
    randomHeroComponent() {
      const heroes = ["Hero00", "Hero01"];
      const randomIndex = Math.floor(Math.random() * heroes.length);
      return heroes[randomIndex];
    },
  },

  mounted() {
    this.setupScrollama();
  },
  data() {
    return {
      showScrollIndicator: true,
      showCookieBar: !localStorage.getItem("cookieAccepted"),
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem("cookieAccepted", "true");
      this.showCookieBar = false;
    },
    goToStep1() {
      this.currentStep = 1;
      const stepElement = document.querySelector('[data-step="1"]');
      if (stepElement) {
        stepElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    goToStep2() {
      this.currentStep = 2;
      const stepElement = document.querySelector('[data-step="2"]');
      if (stepElement) {
        stepElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleStepEnter(response) {
      this.currentStep = parseInt(response.element.dataset.step);
      let easeType = "Power1.easeInOut";
      let easeSpeed = 0.8;
      let easeOffset = 1.25;

      this.currentStep = parseInt(response.element.dataset.step);
      console.log("enter", this.currentStep);
      if (this.currentStep >= 2) {
        this.showScrollIndicator = false;

        let rect = this.$refs.svgContainer.getBoundingClientRect();
        let xOffset = rect.left - 36;
        let yOffset = rect.top - 36;

        TweenLite.to(this.$refs.svgContainer, 0.5, {
          scale: 0.32,
          transformOrigin: "top left",
          x: -xOffset + "px",
          y: -yOffset + "px",
          ease: easeType,
        });

        TweenLite.to(this.$refs.scrollIndicator, 0.5, {
          opacity: 0,
          y: -yOffset * 1.5 + "px",
          ease: easeType,
          cursor: "default",
        });

        TweenLite.fromTo(
          this.$refs.tagline,
          easeSpeed,
          { opacity: 0, y: window.innerHeight },
          { y: "0%", opacity: 1, ease: easeType }
        );

        TweenLite.fromTo(
          this.$refs.socialIcons,
          easeSpeed * easeOffset,
          { opacity: 0, y: window.innerHeight },
          { y: "0%", opacity: 1, ease: easeType }
        );

        TweenLite.fromTo(
          this.$refs.bottomText,
          easeSpeed * easeOffset,
          { opacity: 0, y: window.innerHeight },
          { y: "0%", opacity: 1, ease: easeType }
        );
      } else {
        TweenLite.to(this.$refs.scrollIndicator, 0.5, {
          opacity: 1,
          cursor: "pointer",
        });
        TweenLite.to(this.$refs.svgContainer, easeSpeed, {
          scale: 1,
          transformOrigin: "center center",
          x: "0",
          y: "0",
          ease: easeType,
        });

        TweenLite.to(this.$refs.scrollIndicator, easeSpeed, {
          scale: 1,
          transformOrigin: "center center",
          x: "0",
          y: "0",
          ease: easeType,
        });

        TweenLite.to(this.$refs.tagline, easeSpeed, {
          y: window.innerHeight,
          opacity: 0,
          ease: easeType,
        });

        TweenLite.to(this.$refs.socialIcons, easeSpeed, {
          y: window.innerHeight,
          opacity: 0,
          ease: easeType,
        });

        TweenLite.to(this.$refs.bottomText, easeSpeed, {
          y: window.innerHeight,
          opacity: 0,
          ease: easeType,
        });
      }
    },
    setupScrollama() {
      this.scroller = scrollama();
      this.scroller
        .setup({
          step: ".step",
          offset: 0.4,
          debug: false,
        })
        .onStepEnter(this.handleStepEnter);
    },
  },
};
</script>

<style scoped>
.app {
  font-family: Sora, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  font-size: 16px;
  position: relative;
}
::-moz-selection {
  /* Code for Firefox */
  color: #ffffff;
  background: #ff6145;
}

::selection {
  color: #ffffff;
  background: #ff6145;
}

.second-font {
  font-family: "IBM Plex Mono", monospace;
  font-weight: lighter;
}

#hero-back {
  font-family: Sora, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  background: #f4f4f4;
  position: fixed;
  top: 0;
  z-index: -1;
  font-style: normal !important;
}

.hero {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s;
  position: fixed;
  top: 0;
  left: 0;
}

.subtitle {
  font-size: 1.2em;
  font-weight: 600;
}

.bottom-row {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  transition: all 0.5s;
  position: fixed;
  top: 0;
  left: 0;
}

.bottom-text {
  margin: 48px;
  line-height: 150%;
  text-align: right;
  font-size: 1.2em;
  width: 26vw;
  opacity: 0;
}

.centered-svg {
  position: absolute;
  transform-origin: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  margin: 36px;
}

.centered-svg img {
  width: 720px;
}

#main-logo {
  cursor: pointer;
  pointer-events: all;
}

#tagline {
  font-size: 4em;
  text-align: left;
  width: 100vw;
  margin: 0 36px;
  opacity: 0;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.column {
  flex: 1;
  padding: 1rem;
}

.step {
  height: 100vh;
  margin: 16vh 0;
  /* border: 1px solid red; */
}

.social-icons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 2rem 0;
  gap: 1rem;
  width: 24vh;
  align-self: flex-end;
  padding: 36px;
  opacity: 0;
  cursor: pointer;
}

.social-icon-link {
  text-decoration: none;
}
.social-icon-link:visited {
  color: #000000 !important;
  text-decoration-color: #000000;
}

.icon {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 1em;
  color: #000000;
}

@keyframes bounce2 {
  40% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
}

.icon:hover {
  color: #ff6145;
  animation: bounce2 1.5s infinite;
}

.icon:active {
  color: #007269;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.scroll-indicator {
  animation: bounce 1.8s infinite;

  position: fixed;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 12;
}

.scroll-indicator svg {
  width: 48px;
}

.cookie-bar {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  text-align: center;
  /* z-index: 1; */
}

.cookie-bar button {
  margin-left: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

@media only screen and (max-width: 1199.98px) {
  .bottom-text {
    font-size: 1em;
    margin: 0 24px 36px 0;
    width: 26vw;
  }

  .subtitle {
  font-size: 1.2em;
  font-weight: 600;
}

  
}

@media only screen and (max-width: 767.98px) {
  .centered-svg img {
    width: 360px;
  }

  .bottom-text {
    font-size: 0.9em;
    margin: 0 24px 36px 0;
    width: 48vw;
  }


  .subtitle {
  font-size: 1.2em;
  font-weight: 600;
}

  .social-icons {
    flex-direction: column;
    margin: 0 0 36px 16px;
    padding: 0;
    width: 40vw;
  }

  #tagline {
    font-size: 2.7em;
    margin: 0 24px;
  }

  .icon {
    margin: 0.2em;
  }

  .scroll-indicator {
    position: sticky;
    top: 88%;
    left: 50%;
    z-index: 12;
  }
}
</style>

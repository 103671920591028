<template>
  <div class="noise-layer"></div>
  <div class="holder-p5" ref="canvas"></div>
</template>

<style>
.noise-layer {
  background-image: url("@/assets/doia-noise-160.jpg");
  width: 100vw;
  height: 100vh;
  /* background-blend-mode: multiply; */
  background-blend-mode: overlay;
  opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  filter: brightness(1.5);
}
</style>

<script>
import p5 from "p5";

export default {
  name: "P5Component",
  data() {
    return {
      p5Instance: null,
      parts: [],
      mouseParts: [],
    };
  },
  methods: {
    sketch(p) {
      let self = this;
      let initialParts = 100;
      // let initialParts = 88;
      let initialMouseParts = 42;
      p.setup = function () {
        p.createCanvas(p.windowWidth, p.windowHeight);
        p.background(244);
        p.frameRate(60);
        for (let i = 0; i < initialParts; i++) {
          let newPart = new self.Particle(p);
          self.parts.push(newPart);
        }

        for (let i = 0; i < initialMouseParts; i++) {
          let newPart = new self.Particle(p);
          self.mouseParts.push(newPart);
        }
      };

      p.draw = function () {
        p.background(244, 244, 244, 48);
        for (let part of self.parts) {
          part.mouseAttract(p, p.random(-4, 4));
          part.update(p);
          part.display(p);
        }

        for (let part of self.mouseParts) {
          part.mouseAttract(p, 16);
          part.update(p);
          part.displayNew(p);
        }
      };

      p.mousePressed = function () {
        let partNew = new self.Particle(p, p.mouseX, p.mouseY);
        self.mouseParts.push(partNew);
      };

      p.windowResized = function () {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
        p.background(244, 244, 244);
      };
    },

    Particle: class {
      constructor(p, x = null, y = null) {
        this.p = p;
        this.xoff = this.p.random(150, 200);
        this.yoff = this.p.random(150, 200);
        this.initialX =
          x !== null ? x : p.map(p.noise(this.xoff), 0, 1, 0, p.width);
        this.initialY =
          y !== null ? y : p.map(p.noise(this.yoff), 0, 1, 0, p.height);

        this.pos = p.createVector(this.initialX, this.initialY);
        this.vel = p.createVector(0, 0);
        this.acc = p.createVector(0, 0);

        this.size = p.random(6, 12);
        this.alpha = p.random(10, 50);
        this.colR = p.random(255);
        this.colG = p.random(255);
        this.colB = p.random(255);
        this.shouldAnimate = false;
      }

      applyForce(force) {
        this.acc.add(force);
      }

      mouseAttract(p, attMag) {
        let mouse = p.createVector(p.mouseX, p.mouseY);
        let mouseForce = mouse.sub(this.pos);
        mouseForce.setMag(attMag);
        this.applyForce(mouseForce);
      }

      update(p) {
        this.xoff += 0.005;
        this.yoff += 0.005;
        let dx = this.p.map(this.p.noise(this.xoff), 0, 1, -2, 2);
        let dy = this.p.map(this.p.noise(this.yoff), 0, 1, -2, 2);
        this.applyForce(this.p.createVector(dx, dy));

        this.vel.add(this.acc);
        this.pos.add(this.vel);
        this.acc.mult(0);
        this.vel.limit(4);

        this.colR = p.map(p.noise(this.xoff), 0, 1, 100, 220);
        this.colG = p.map(p.noise(this.xoff), 0, 1, 120, 255);
        // this.colB = p.map(p.noise(this.xoff), 0, 1, 69, 200);
        this.colB = p.map(p.noise(this.xoff), 0, 1, 139, 190);
        2;
        this.alpha = p.map(p.noise(this.xoff), 0, 1, 0, 64);
        this.size = p.map(p.noise(this.xoff), 0, 1, 1, 720);

        if (this.pos.x > p.width || this.pos.x < 0) {
          this.vel.x = -this.vel.x;
        }
        if (this.pos.y > p.height || this.pos.y < 0) {
          this.vel.y = -this.vel.y;
        }
        this.pos.x = p.constrain(this.pos.x, 0, p.width);
        this.pos.y = p.constrain(this.pos.y, 0, p.height);
      }

      display(p) {
        p.stroke(this.colR, this.colG, this.colB, this.alpha * 0.2);
        // p.noStroke();
        p.strokeWeight(this.size);
        p.point(this.pos.x, this.pos.y, this.size, this.size);
      }

      displayNew(p) {
        this.xoff += 0.005;
        this.yoff += 0.005;

        // p.fill(255, this.colG, this.colB, this.alpha * 0.8);
        p.stroke(255, this.colG, this.colB, this.alpha * 0.8);
        p.strokeWeight(this.size);

        p.point(
          this.initialX + this.pos.x * 0.5,
          this.initialY + this.pos.y * 0.5
        );
      }
    },

    createCanvas() {
      this.p5Instance = new p5(this.sketch, this.$refs.canvas);
    },

    unmountCanvas() {
      this.p5Instance.remove();
    },
  },
  mounted() {
    this.createCanvas();
  },
  beforeUnmount() {
    this.unmonuntCanvas();
  },
};
</script>
